<template>
  <v-col
    cols="12"
    md="12"
  >
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <p class="font-size-18 font-weight-700">
            Edit Profile
          </p>
          <ValidationObserver
            ref="edit_profile_form_observer"
            v-slot="{ handleSubmit }"
          >
            <v-form @submit.prevent="handleSubmit(updateProfile)">
              <v-container
                fluid
                class="ma-0 pa-0"
              >
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors }"
                      vid="doctor_full_name"
                      name="Full Name"
                      rules="required"
                    >
                      <v-text-field
                        v-model="formData.name"
                        name="doctor_full_name"
                        outlined
                        label="Full Name *"
                        placeholder="Full Name"
                        type="text"
                        hide-details="auto"
                        class="mb-4"
                        dense
                        :error-messages="errors"
                      />
                    </ValidationProvider>

                    <ValidationProvider
                      v-slot="{ errors }"
                      vid="designation"
                      name="Designation"
                      rules="required"
                    >
                      <v-text-field
                        v-model="formData.designation"
                        name="designation"
                        outlined
                        label="Designation *"
                        placeholder="Designation"
                        type="text"
                        hide-details="auto"
                        class="mb-4"
                        dense
                        :error-messages="errors"
                      />
                    </ValidationProvider>

                    <ValidationProvider
                      v-slot="{ errors }"
                      vid="hospital"
                      name="Hospital Address"
                      rules="required"
                    >
                      <v-text-field
                        v-model="formData.hospital"
                        name="hospital"
                        outlined
                        label="Hospital Address *"
                        placeholder="Hospital Address"
                        type="text"
                        hide-details="auto"
                        class="mb-4"
                        dense
                        :error-messages="errors"
                      />
                    </ValidationProvider>

                    <ValidationProvider
                      v-slot="{ errors }"
                      vid="specialization"
                      name="Specialization"
                      rules="required"
                    >
                      <v-text-field
                        v-model="formData.specialization"
                        name="specialization"
                        outlined
                        label="Specialization *"
                        placeholder="Area of Interests / Specialization"
                        type="text"
                        hide-details="auto"
                        class="mb-4"
                        dense
                        :error-messages="errors"
                      />
                    </ValidationProvider>

                    <ValidationProvider
                      v-slot="{ errors }"
                      vid="phone"
                      rules="required"
                      name="Phone Number"
                    >
                      <v-text-field
                        v-model="formData.phoneNo"
                        hide-details="auto"
                        outlined
                        dense
                        label="Phone Number *"
                        placeholder="01XXXXXXXXX"
                        maxlength="11"
                        class="mb-4"
                        name="phone"
                        :error-messages="errors"
                      />
                    </ValidationProvider>

                    <v-menu
                      ref="dateofbirth"
                      v-model="dateOfBirth"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template #activator="{ on, attrs }">
                        <ValidationProvider
                          v-slot="{ errors }"
                          vid="date_of_birth"
                          name="Date of Birth"
                          rules="required"
                        >
                          <v-text-field
                            v-model="formData.dob"
                            name="date_of_birth"
                            label="Date of Birth *"
                            outlined
                            dense
                            readonly
                            hide-details="auto"
                            append-icon="mdi-calendar"
                            v-bind="attrs"
                            :error-messages="errors"
                            v-on="on"
                            @click:append="dateOfBirth = !dateOfBirth"
                          />
                        </ValidationProvider>
                      </template>
                      <v-date-picker
                        v-model="formData.dob"
                        no-title
                        @input="dateOfBirth = false"
                      />
                    </v-menu>

                    <v-container>
                      <v-row>
                        <v-col
                          cols="6"
                          class="pl-0"
                        >
                          <ValidationProvider
                            v-slot="{ errors }"
                            vid="bm_dc_type"
                            name="BM & DC Type"
                            rules="required"
                          >
                            <v-select
                              v-model="formData.docType"
                              name="bm_dc_type"
                              hide-details="auto"
                              :items="items"
                              label="BM & DC Type *"
                              required
                              outlined
                              :error-messages="errors"
                              dense
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col
                          cols="6"
                          class="pr-0"
                        >
                          <ValidationProvider
                            v-slot="{ errors }"
                            vid="bm_dc_number"
                            rules="required"
                            name="BM & DC Number"
                          >
                            <v-text-field
                              v-model="formData.bmdcNo"
                              name="bm_dc_number"
                              hide-details="auto"
                              outlined
                              dense
                              label="BM & DC Number *"
                              placeholder="BM & DC Number"
                              maxlength="20"
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-container>
                      <v-row>
                        <v-col
                          cols="6"
                          class="pl-0 pt-0"
                        >
                          <ValidationProvider
                            v-slot="{ errors }"
                            vid="gender"
                            name="Select Gender"
                            rules="required"
                          >
                            <v-select
                              v-model="formData.gender"
                              name="gender"
                              hide-details="auto"
                              :items="genders"
                              label="Select Gender *"
                              required
                              outlined
                              :error-messages="errors"
                              dense
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col
                          cols="6"
                          class="pr-0 pt-0"
                        >
                          <ValidationProvider
                            v-slot="{ errors }"
                            vid="blood_group"
                            rules="required"
                            name="Blood Group"
                          >
                            <v-text-field
                              v-model="formData.bloodGroup"
                              name="blood_group"
                              hide-details="auto"
                              outlined
                              dense
                              label="Blood Group *"
                              placeholder="Blood Group"
                              maxlength="20"
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                    </v-container>

                    <ValidationProvider
                      v-slot="{ errors }"
                      vid="email"
                      rules="required"
                      name="E-mail"
                    >
                      <v-text-field
                        v-model="formData.email"
                        name="email"
                        hide-details="auto"
                        outlined
                        dense
                        type="email"
                        label="E-mail *"
                        placeholder="E-mail"
                        maxlength="20"
                        class="mb-4"
                        :error-messages="errors"
                      />
                    </ValidationProvider>

                    <ValidationProvider
                      v-slot="{ errors }"
                      vid="doctor_image"
                      name="image"
                    >
                      <v-file-input
                        v-model="formData.image"
                        name="image"
                        hide-details="auto"
                        outlined
                        dense
                        append-icon="mdi-cloud-upload-outline"
                        prepend-icon=""
                        small-chips
                        label="Image"
                        :error-messages="errors"
                        show-size
                        placeholder="Choose Image"
                        accept="image/png,image/jpeg,image/jpg"
                        type="file"
                        @click:clear="profile_image = null"
                        @change="onFileUpload"
                      />
                    </ValidationProvider>

                    <v-col
                      cols="12"
                      class="d-flex justify-end pr-0"
                    >
                      <v-btn
                        type="submit"
                        class="text-capitalize font-weight-bold"
                        color="orange"
                        outlined
                      >
                        Confirm
                      </v-btn>
                    </v-col>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </v-col>
      </v-row>
      <v-snackbar
        v-model="snackbar.action"
        :timeout="snackbar.timeout"
        top
        right
        :color="snackbar.color"
        class="font-weight-bold"
      >
        {{ snackbar.text }}
      </v-snackbar>
    </v-container>
  </v-col>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { postData } from "@/helpers/apiRuquests";

extend('required', {
  ...required,
  message: "The {_field_} field is required."
});

export default {
  name: "Profile",
  components: { ValidationObserver, ValidationProvider },
  data: () => ({
    formData: {
      name: '',
      email: '',
      bloodGroup: '',
      bmdcNo: '',
      designation: '',
      dob: '',
      gender: '',
      hospital: '',
      image: '',
      phoneNo: '',
      specialization: '',
      type: 'doctor',
      docType: '',
    },
    dateOfBirth: false,
    items: ['general', 'dentist'],
    genders: ['Male', 'Female', 'Others'],
    snackbar: {
      action: false,
      text: '',
      timeout: 5000,
      color: null
    }
  }),
  created() {
    this.getProfile();
  },
  methods: {
    updateProfile() {
      const formData = new FormData();

      console.log(this.formData)
      
      for (let item in this.formData) {
        formData.append(item, this.formData[item]);
      }
      formData.append("image", this.formData.image.name);

      delete this.formData["createdAt"];
      delete this.formData["updatedAt"];

      postData(
        "profile", formData,
        () => {
          this.successResponse();

        },
        error => {
          this.errorResponse(error);

        }
      )
    },
    async getProfile() {
      let response = await this.$store.dispatch('profileStore/getProfile');
      if (response?.data) {
        this.formData = this.$store.getters["profileStore/profileData"];
      }
    },
    successResponse() {
      this.snackbar = {
        action: true,
        text: "Successfully Registered",
        color: "orange"
      }

      this.$nextTick(() => {
        this.$refs.edit_profile_form_observer.reset();
      });

      this.formData = {
        name: '',
        email: '',
        bloodGroup: '',
        bmdcNo: '',
        designation: '',
        dob: '',
        gender: '',
        hospital: '',
        image: null,
        phoneNo: '',
        specialization: '',
        type: 'doctor',
        docType: '',
      };
    },
    errorResponse(error) {
      this.snackbar = {
        action: true,
        text: error.response.data.Message,
        color: "error"
      }
    },
    onFileUpload() {
      if (EventTarget.target.files) {
        let file = EventTarget.target.files[0];
        let reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            this.profile_image = reader.result;
          },
          false
        );
        if (file) reader.readAsDataURL(file);
        this.formData.image = file;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.border {
  border: 2px dashed orange;
}

.item-list {
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>